import { useDispatch, useSelector } from 'react-redux'
import { selectRoles } from '../../../store/slices/roles/selectors'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { ROLE_COLUMNS_CONFIG } from './tableConfig'
import { fetchRolesAction } from '../../../store/slices/roles/actions'
import { ERROR_NOTIFICATION_OPTIONS } from '../../../configs/constants'
import { useModal } from '../../../hooks/useModal'
import {
  StyledContentWrapper,
  StyledTableBodyWrapper,
  StyledTableFooterWrapper,
  StyledTableHeaderWrapper,
} from './elements'
import { Button, InputAdornment, Pagination, TextField } from '@mui/material'
import { Add, SearchOutlined } from '@mui/icons-material'
import { Modal } from '../../../components/Modal'
import { Table } from '../../../components/Table'
import { RoleCreateForm } from '../../../components/RoleCreateForm'

const ROWS_LIMIT = 20
const FETCH_ERROR_MESSAGE = 'An error occurred while fetching roles!'

const Roles = (): JSX.Element => {
  const roleList = useSelector(selectRoles)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: any = useMemo(() => ROLE_COLUMNS_CONFIG, [])
  const [search, setSearch] = useState<string>('')
  const [total, setTotal] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const { modalStatus, openModalHandler, closeModalHandler } = useModal()

  useEffect(() => {
    dispatch(
      fetchRolesAction({
        offset: (pageNumber - 1) * ROWS_LIMIT,
        limit: ROWS_LIMIT,
        search: search,
        postSuccessHandler: (total) => {
          setTotal(total)
        },
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
          console.error(FETCH_ERROR_MESSAGE)
        },
      })
    )
  }, [dispatch, enqueueSnackbar, pageNumber, search])

  const postCreationHander = () => {
    dispatch(
      fetchRolesAction({
        offset: (pageNumber - 1) * ROWS_LIMIT,
        limit: ROWS_LIMIT,
        search: search,
        postSuccessHandler: (total) => {
          setTotal(total)
        },
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
          console.error(FETCH_ERROR_MESSAGE)
        },
      })
    )
    closeModalHandler()
  }

  const pageChangeHandler = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(
      fetchRolesAction({
        offset: (value - 1) * ROWS_LIMIT,
        limit: ROWS_LIMIT,
        search: search,
        postSuccessHandler: (total) => {
          setPageNumber(value)
          setTotal(total)
        },
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
          console.error(FETCH_ERROR_MESSAGE)
        },
      })
    )
  }

  const searchChangeHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearch(event.target.value)
    dispatch(
      fetchRolesAction({
        offset: 0,
        limit: ROWS_LIMIT,
        search: event.target.value,
        postSuccessHandler: (total) => {
          setPageNumber(1)
          setTotal(total)
        },
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
          console.error(FETCH_ERROR_MESSAGE)
        },
      })
    )
  }

  return (
    <StyledContentWrapper>
      <StyledTableHeaderWrapper>
        <TextField
          placeholder="Search"
          sx={{ mr: 1 }}
          size="small"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
          onChange={searchChangeHandler}
        />
        <Button
          color="secondary"
          variant="contained"
          startIcon={<Add />}
          onClick={openModalHandler}
        >
          Create Access Group
        </Button>
        <Modal
          open={modalStatus}
          onCancel={closeModalHandler}
          title={'New Access Group'}
          content={
            <RoleCreateForm postSubmitActionHandler={postCreationHander} />
          }
        />
      </StyledTableHeaderWrapper>

      <StyledTableBodyWrapper>
        <Table columns={columns} data={roleList} />
        <StyledTableFooterWrapper>
          <Pagination
            count={Math.ceil(total / ROWS_LIMIT)}
            color="secondary"
            page={pageNumber}
            onChange={pageChangeHandler}
          />
        </StyledTableFooterWrapper>
      </StyledTableBodyWrapper>
    </StyledContentWrapper>
  )
}

export default Roles
