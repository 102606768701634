import { Box, Card, Typography } from '@mui/material'
import { styled } from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 70px 0;
`

export const StyledCard = styled(Card)`
  padding: 5%;
  min-width: 450px;
`
export const StyledInviteHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 20px 0;
`

export const StyledButtonWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  margin: 30px 0 10px;
  align-items: center;
`

export const StyledImg = styled.img`
  width: 100px;
  margin: 0 0 25px 0;
`
export const StyledSubtitle = styled(Typography)`
  margin-top: 7px !important;
  color: rgb(255, 255, 255, 0.5);
`
export const StyledBody = styled(Typography)`
  margin-top: 35px !important;
`
