import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { useSnackbar } from 'notistack'
import { SearchOutlined, CopyAll } from '@mui/icons-material'
import {
  Button,
  InputAdornment,
  Pagination,
  TextField,
  Tooltip,
} from '@mui/material'

import {
  StyledContentWrapper,
  StyledTableBodyWrapper,
  StyledTableFooterWrapper,
  StyledTableHeaderWrapper,
  StyledTableTitle,
} from './elements'
import { Table } from '../../components/Table'
import { selectUserlist } from '../../store/slices/userlist/selectors'
import { USERLIST_COLUMNS_CONFIG } from './tableConfig'
import { fetchUserlistAction } from '../../store/slices/userlist/actions'
import {
  ERROR_NOTIFICATION_OPTIONS,
  SUCCESS_NOTIFICATION_OPTIONS,
  WARNING_NOTIFICATION_OPTIONS,
} from '../../configs/constants'
import { fetchActiveUserlistAction } from '../../store/slices/activeUsers/actions'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

const ROWS_LIMIT = 20
const INITIAL_PAGE_NUMBER = 1
const INITIAL_SEARCH = ''
const FETCH_ERROR_MESSAGE = 'An error occurred while fetching users!'

const Users = (): JSX.Element => {
  const userlist = useAppSelector(selectUserlist)
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [search, setSearch] = useState<string>(INITIAL_SEARCH)
  const [total, setTotal] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(INITIAL_PAGE_NUMBER)

  useEffect(() => {
    dispatch(
      fetchUserlistAction({
        offset: (INITIAL_PAGE_NUMBER - 1) * ROWS_LIMIT,
        limit: ROWS_LIMIT,
        search: INITIAL_SEARCH,
        postSuccessHandler: (total) => {
          setTotal(total)
        },
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
        },
      })
    )
  }, [dispatch, enqueueSnackbar])

  const pageChangeHandler = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(
      fetchUserlistAction({
        offset: (value - 1) * ROWS_LIMIT,
        limit: ROWS_LIMIT,
        search: search,
        postSuccessHandler: (total) => {
          setPageNumber(value)
          setTotal(total)
        },
        errorHandler: () => {
          enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
        },
      })
    )
  }

  const debouncedSearchChangeHandler = useMemo(
    () =>
      debounce((query: string) => {
        setSearch(query)
        dispatch(
          fetchUserlistAction({
            offset: 0,
            limit: ROWS_LIMIT,
            search: query,
            postSuccessHandler: (total) => {
              setPageNumber(1)
              setTotal(total)
            },
            errorHandler: () => {
              enqueueSnackbar(FETCH_ERROR_MESSAGE, ERROR_NOTIFICATION_OPTIONS)
            },
          })
        )
      }, 500),
    [dispatch, enqueueSnackbar]
  )

  const searchChangeHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    debouncedSearchChangeHandler(event.target.value)
  }

  const copyEmailsToClipboard = () => {
    dispatch(
      fetchActiveUserlistAction({
        offset: 0,
        limit: 2000,
        search: '',
        enabled: true,
        postSuccessHandler: (emailString) => {
          if (emailString) {
            navigator.clipboard.writeText(emailString)
            enqueueSnackbar(
              'Active User email(s) copied to clipboard successfully!',
              SUCCESS_NOTIFICATION_OPTIONS
            )
          } else {
            enqueueSnackbar(
              'No active users found!',
              WARNING_NOTIFICATION_OPTIONS
            )
          }
        },
        errorHandler: () => {
          enqueueSnackbar(
            'An error occurred while fetching active users!',
            ERROR_NOTIFICATION_OPTIONS
          )
          console.error('an error occurred while fetching active users')
        },
      })
    )
  }

  return (
    <StyledContentWrapper>
      <StyledTableHeaderWrapper>
        <StyledTableTitle>Users</StyledTableTitle>
        <TextField
          placeholder="Search"
          sx={{ mr: 1 }}
          size="small"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
          onChange={searchChangeHandler}
        />
        <Tooltip title="Copy all active user emails">
          <Button
            color="secondary"
            variant="contained"
            startIcon={<CopyAll />}
            onClick={copyEmailsToClipboard}
          >
            Copy All Email(s)
          </Button>
        </Tooltip>
      </StyledTableHeaderWrapper>

      <StyledTableBodyWrapper>
        <Table columns={USERLIST_COLUMNS_CONFIG} data={userlist} />
        <StyledTableFooterWrapper>
          <Pagination
            count={Math.ceil(total / ROWS_LIMIT)}
            color="secondary"
            page={pageNumber}
            onChange={pageChangeHandler}
          />
        </StyledTableFooterWrapper>
      </StyledTableBodyWrapper>
    </StyledContentWrapper>
  )
}

export default Users
