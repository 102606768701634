import * as yup from 'yup'
import { Button, MenuItem } from '@mui/material'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import lodash from 'lodash'

import { selectGroups } from '../../store/slices/group/slice'
import { StyledButtonWrapper, StyledForm, StyledTextField } from './elements'
import { CreateInvitationRequestPayload } from '../../api/invitations/models'
import { createInvitationAction } from '../../store/slices/invitation/actions'
import {
  ERROR_NOTIFICATION_OPTIONS,
  SHOULD_INCLUDE_ORGANIZATIONS,
  SUCCESS_NOTIFICATION_OPTIONS,
} from '../../configs/constants'
import { fetchGroupsAction } from '../../store/slices/group/actions'
import { useAppDispatch, useAppSelector } from '../../store/hooks'

interface InvitationCreateFormProps {
  postSubmitActionHandler: () => void
}

type FormValues = CreateInvitationRequestPayload

/** intital form value */
const INITIAL_FORM_VALUE: FormValues = {
  groupId: '',
  email: '',
  groupName: '',
}

const validationSchemaWithOrg = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  groupId: yup.string().required('Organization is required'),
})

const validationSchemaWithoutOrg = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
})

const InvitationCreateForm: React.FC<InvitationCreateFormProps> = ({
  postSubmitActionHandler,
}): JSX.Element => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const groups = useAppSelector(selectGroups)

  useEffect(() => {
    if (SHOULD_INCLUDE_ORGANIZATIONS) {
      dispatch(
        fetchGroupsAction({
          limit: 2000,
          postSuccessHandler: () => undefined,
          errorHandler: () => {
            enqueueSnackbar(
              'An error occurred while fetching organizations!',
              ERROR_NOTIFICATION_OPTIONS
            )
            console.error('an error occurred while fetching organizations')
          },
        })
      )
    }
  }, [dispatch, enqueueSnackbar])

  const formik = useFormik<FormValues>({
    initialValues: INITIAL_FORM_VALUE,
    validationSchema: SHOULD_INCLUDE_ORGANIZATIONS
      ? validationSchemaWithOrg
      : validationSchemaWithoutOrg,
    onSubmit: (values: FormValues) => {
      dispatch(
        createInvitationAction({
          ...values,
          postSuccessHandler: () => {
            enqueueSnackbar(
              'Invitation sent successfully!',
              SUCCESS_NOTIFICATION_OPTIONS
            )
            postSubmitActionHandler()
          },
          errorHandler: () => {
            enqueueSnackbar(
              'Failed to send invitation!',
              ERROR_NOTIFICATION_OPTIONS
            )
          },
        })
      )
    },
  })

  const onOrganizationSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setValues({
      ...formik.values,
      groupId: event.target.value,
      groupName: lodash.find(groups, { id: event.target.value })?.name || '',
    })
  }

  return (
    <div>
      <StyledForm onSubmit={formik.handleSubmit}>
        <StyledTextField
          id="email"
          name="email"
          label="Email"
          margin="normal"
          size="small"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        {SHOULD_INCLUDE_ORGANIZATIONS && (
          <StyledTextField
            id="groupId"
            select
            size="small"
            name="groupId"
            label="Organization"
            margin="normal"
            value={formik.values.groupId}
            onChange={onOrganizationSelect}
            error={formik.touched.groupId && Boolean(formik.errors.groupId)}
            helperText={formik.touched.groupId && formik.errors.groupId}
          >
            {groups.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </StyledTextField>
        )}
        <StyledButtonWrapper>
          <Button color="secondary" variant="contained" type="submit">
            Send Invitation
          </Button>
        </StyledButtonWrapper>
      </StyledForm>
    </div>
  )
}

export default InvitationCreateForm
