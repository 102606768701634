/** internal Routes */
export const LOCAL_HOME_ROUTE = 'home'
export const LOCAL_LOGIN_ROUTE = 'login'
export const LOCAL_COMPANY_ROUTE = 'organizations'
export const LOCAL_ADMIN_ROUTE = 'admin'
export const LOCAL_PROFILE_ROUTE = 'profile'
export const LOCAL_SECURITY_INFO_ROUTE = 'security-info'
export const LOCAL_USERS_ROUTE = 'users'
export const LOCAL_INVITATIONS_ROUTE = 'invitations'
export const LOCAL_ACCESS_CONTROL_ROUTE = 'access-control'
export const LOCAL_INVITE_ROUTE = '/invite/:token/:expiry'
