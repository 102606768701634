import { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'
import { setSecurityInfoAction } from './slice'
import {
  fetchSecurityInfoAction,
  updateSecurityInfoAction,
  UpdateSecurityInfoPayload,
} from './actions'
import {
  getSecurityInfoFromServer,
  updateServerSecurityInfo,
} from '../../../api/securityInfo/requests'

function* fetchSecurityInfoAsync(): SagaIterator {
  try {
    const response = yield call(getSecurityInfoFromServer)
    yield put(setSecurityInfoAction(response))
  } catch (exception) {
    console.log(exception)
  }
}

function* updateSecurityInfoAsync(action: {
  payload: UpdateSecurityInfoPayload
}): SagaIterator {
  const { securityInfo, postSuccessHandler, errorHandler } = action.payload
  try {
    yield call(() => updateServerSecurityInfo(securityInfo))
    yield put(setSecurityInfoAction(securityInfo))
    yield call(postSuccessHandler)
  } catch (exception) {
    yield call(errorHandler)
    console.log(exception)
  }
}

export function* securityInfoSaga(): SagaIterator {
  yield takeLatest(fetchSecurityInfoAction, fetchSecurityInfoAsync)
  yield takeLatest(updateSecurityInfoAction, updateSecurityInfoAsync)
}
