import { ConfirmModalProps } from '../../components/ConfirmModal/ConfirmModal'
import { useModal } from '../useModal'

interface UseConfirmModalProps {
  title: string
  description?: string
  keepModalOpenAfterOk?: boolean
  keepModalOpenAfterCancel?: boolean
  hideCancel?: boolean
  okText?: string
  cancelText?: string
  onOk: (closeModalHandler: () => void) => void
  onCancel?: (closeModalHandler: () => void) => void
}

interface UseConfirmModal {
  getConfirmModalProps: ConfirmModalProps
  openConfirmationModal: () => void
}

const useConfirmModal = ({
  title,
  description,
  keepModalOpenAfterOk,
  keepModalOpenAfterCancel,
  hideCancel,
  okText,
  cancelText,
  onOk,
  onCancel,
}: UseConfirmModalProps): UseConfirmModal => {
  const { modalStatus, openModalHandler, closeModalHandler } = useModal()

  const decoratedOnOK = () => {
    onOk(closeModalHandler)
    if (!keepModalOpenAfterOk) closeModalHandler()
  }

  const decoratedOnCancel = () => {
    if (onCancel) onCancel(closeModalHandler)
    if (!keepModalOpenAfterCancel) closeModalHandler()
  }

  return {
    getConfirmModalProps: {
      open: modalStatus,
      title,
      description,
      hideCancel,
      okText,
      cancelText,
      onOk: decoratedOnOK,
      onCancel: decoratedOnCancel,
    },
    openConfirmationModal: openModalHandler,
  }
}

export default useConfirmModal
