import { Card } from '@mui/material'
import { styled } from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 70px 0;
`

export const StyledCard = styled(Card)`
  padding: 5%;
`
