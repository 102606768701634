import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import {
  AuthenticationTypes,
  ERROR_NOTIFICATION_OPTIONS,
  SUCCESS_NOTIFICATION_OPTIONS,
} from '../../configs/constants'
import {
  StyledHeaderWrapper,
  SecurityFormContainer,
  StyledButton,
  StyledButtonWrapper,
  StyledFormControl,
  StyledFormControlLabel,
} from './elements'
import { selectSecurityInfo } from '../../store/slices/securityInfo/slice'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  fetchSecurityInfoAction,
  updateSecurityInfoAction,
} from '../../store/slices/securityInfo/actions'
import { useSnackbar } from 'notistack'

const SecurityForm = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false)
  const [checked, setChecked] = useState(false)
  const [authenticationType, setAuthenticationType] =
    useState<AuthenticationTypes>(AuthenticationTypes.googleAuthenticator)
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    dispatch(fetchSecurityInfoAction())
  }, [dispatch])

  const currentSecurityInfo = useAppSelector(selectSecurityInfo)

  useEffect(() => {
    setChecked(currentSecurityInfo.tfa)
    setAuthenticationType(currentSecurityInfo.authenticationType)
  }, [currentSecurityInfo])

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }
  const handleRadioGroupChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAuthenticationType(event.target.value as AuthenticationTypes)
  }
  const handleSubmit = () => {
    dispatch(
      updateSecurityInfoAction({
        securityInfo: {
          tfa: checked,
          authenticationType:
            authenticationType || AuthenticationTypes.googleAuthenticator,
        },
        postSuccessHandler: () => {
          setLoading(false)
          enqueueSnackbar(
            'Updated authentication settings successfully',
            SUCCESS_NOTIFICATION_OPTIONS
          )
        },
        errorHandler: () => {
          setLoading(false)
          enqueueSnackbar(
            'Failed to update authentication settings',
            ERROR_NOTIFICATION_OPTIONS
          )
        },
      })
    )
  }

  return (
    <SecurityFormContainer>
      <StyledHeaderWrapper>
        <Typography variant="h4" noWrap={true}>
          Account Protection
        </Typography>
      </StyledHeaderWrapper>
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={handleSwitchChange}
            color="secondary"
          />
        }
        label="Two Factor Authentication"
      />
      <StyledFormControl variant="standard" disabled={!checked}>
        <RadioGroup
          value={authenticationType}
          onChange={handleRadioGroupChange}
        >
          <StyledFormControlLabel
            value={AuthenticationTypes.googleAuthenticator}
            control={<Radio />}
            label={AuthenticationTypes.googleAuthenticator}
          />
        </RadioGroup>
      </StyledFormControl>
      <StyledButtonWrapper>
        <StyledButton
          disabled={
            loading ||
            (currentSecurityInfo.authenticationType === authenticationType &&
              currentSecurityInfo.tfa === checked)
          }
          color="secondary"
          variant="contained"
          type="submit"
          onClick={handleSubmit}
        >
          Save
        </StyledButton>
      </StyledButtonWrapper>
    </SecurityFormContainer>
  )
}

export default SecurityForm
