import { styled } from 'styled-components'

export const StyledContentWrapper = styled.div`
  width: 100%;
`
export const StyledTableHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const StyledTableBodyWrapper = styled.div`
  padding-top: 15px;
`
export const StyledTableTitle = styled.div`
  font-size: 1.3rem;
  flex-grow: 1;
`

export const StyledTableFooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
`
