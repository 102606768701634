import { axioService, GET, PUT } from '../../services/axios'
import { SERVER_SECURITY_INFO_ENDPOINT } from '../../configs/endpoints'
import { SecurityInfoObj } from '../../store/slices/securityInfo/slice'
import { mapSecurityInfoFromServer } from './mappings'

export const getSecurityInfoFromServer = async (): Promise<SecurityInfoObj> => {
  const response = await axioService(
    GET,
    SERVER_SECURITY_INFO_ENDPOINT,
    {},
    true
  )
  return mapSecurityInfoFromServer(response.data)
}

export const updateServerSecurityInfo = async (
  requested: SecurityInfoObj
): Promise<SecurityInfoObj> => {
  const response = await axioService(
    PUT,
    SERVER_SECURITY_INFO_ENDPOINT,
    {
      tfa: requested.tfa,
      authentication_type: requested.authenticationType,
    },
    true
  )
  return mapSecurityInfoFromServer(response.data)
}
