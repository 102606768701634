import { AuthenticationTypes } from '../../../configs/constants'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Store } from '../../index'

/** interface to define the SecurityInfo Info object */
export interface SecurityInfoObj {
  tfa: boolean
  authenticationType: AuthenticationTypes
}

/** interface to declare security info state */
type SecurityInfoState = SecurityInfoObj

/** the security info reducer name **/
export const reducerName = 'securityInfo'

/** initial reducer state */
export const initialSecurityInfoState: SecurityInfoState = {
  tfa: false,
  authenticationType: AuthenticationTypes.googleAuthenticator,
}

/** slice */
const securityInfoSlice = createSlice({
  name: reducerName,
  initialState: initialSecurityInfoState,
  reducers: {
    setSecurityInfo(state, action: PayloadAction<SecurityInfoObj>) {
      return action.payload
    },
    resetSecurityInfo() {
      return initialSecurityInfoState
    },
  },
})

/** actions */
/** export the security info reducer actions */
export const {
  setSecurityInfo: setSecurityInfoAction,
  resetSecurityInfo: resetSecurityInfoAction,
} = securityInfoSlice.actions

/** selectors */
/** returns the security info in state
 *  @param {Store} state - the redux store
 *  @return {SecurityInfoObj} - the existing security info
 */
export function selectSecurityInfo(state: Store): SecurityInfoObj {
  return state[reducerName]
}

/** export the security info default reducer */
export default securityInfoSlice.reducer
