import { Card } from '@mui/material'
import { styled } from 'styled-components'

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 70px 0;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
`

export const StyledCard = styled(Card)`
  padding: 3% 5%;
  margin: 0 10px 20px;
`
