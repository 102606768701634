import { ServerSecurityInfoObj } from './models'
import { SecurityInfoObj } from '../../store/slices/securityInfo/slice'
import { AuthenticationTypes } from '../../configs/constants'

export const mapSecurityInfoFromServer = (
  requested: ServerSecurityInfoObj
): SecurityInfoObj => {
  return {
    tfa: requested.tfa,
    authenticationType:
      requested.authentication_type || AuthenticationTypes.googleAuthenticator,
  }
}
