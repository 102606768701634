import { StyledCard, StyledContainer } from './elements'
import { SecurityForm } from '../../components/SecurityForm'
import { PasswordUpdateForm } from '../../components/PasswordUpdateForm'

const SecurityInfo = () => {
  return (
    <StyledContainer>
      <StyledCard>
        <PasswordUpdateForm />
      </StyledCard>
      <StyledCard>
        <SecurityForm />
      </StyledCard>
    </StyledContainer>
  )
}

export default SecurityInfo
