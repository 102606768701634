import { Alert, Box, TextField } from '@mui/material'
import { styled } from 'styled-components'

export const StyledForm = styled.form`
  min-width: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 0 10px;
`
export const StyledTextField = styled(TextField)`
  width: 350px;
  margin: 0 0 25px;
`
export const StyledButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin: 30px 0 10px;
`

export const StyledAlert = styled(Alert)`
  width: 350px;
`
