import * as yup from 'yup'
import { Button, MenuItem } from '@mui/material'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'

import { StyledButtonWrapper, StyledForm, StyledTextField } from './elements'
import { CreatePermissionRequestPayload } from '../../api/roles/models'
import { createPermissionAction } from '../../store/slices/roles/actions'
import {
  ERROR_NOTIFICATION_OPTIONS,
  SUCCESS_NOTIFICATION_OPTIONS,
} from '../../configs/constants'
import { selectNamespaces } from '../../store/slices/namespace/slice'
import { fetchNamespacesAction } from '../../store/slices/namespace/actions'

const FETCH_NAMESPACES_ERROR_MESSAGE =
  'An error occurred while fetching namespaces!'

interface PermissionCreateFormProps {
  roleId: string
  postSubmitActionHandler: () => void
}

type FormValues = CreatePermissionRequestPayload

/** intital form value */
const INITIAL_FORM_VALUE: FormValues = {
  roleId: '',
  namespaceId: '',
  allowedAction: '',
}

const validationSchema = yup.object({
  namespaceId: yup.string().required('Namespace is required'),
  allowedAction: yup.string().required('Action is required'),
})

const PermissionCreateForm: React.FC<PermissionCreateFormProps> = ({
  roleId,
  postSubmitActionHandler,
}): JSX.Element => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const namespaces = useSelector(selectNamespaces)
  const allowedActionOptions = [
    { id: 'read', name: 'Read' },
    { id: 'write', name: 'Write' },
  ]

  useEffect(() => {
    dispatch(
      fetchNamespacesAction({
        limit: 2000,
        offset: 0,
        search: '',
        postSuccessHandler: () => undefined,
        errorHandler: () => {
          enqueueSnackbar(
            FETCH_NAMESPACES_ERROR_MESSAGE,
            ERROR_NOTIFICATION_OPTIONS
          )
        },
      })
    )
  }, [dispatch, enqueueSnackbar])

  const formik = useFormik<FormValues>({
    initialValues: INITIAL_FORM_VALUE,
    validationSchema: validationSchema,
    onSubmit: (values: FormValues) => {
      dispatch(
        createPermissionAction({
          ...values,
          roleId,
          postSuccessHandler: () => {
            enqueueSnackbar(
              'Permission added successfully!',
              SUCCESS_NOTIFICATION_OPTIONS
            )
            postSubmitActionHandler()
          },
          errorHandler: () => {
            enqueueSnackbar(
              'Failed to add permission!',
              ERROR_NOTIFICATION_OPTIONS
            )
          },
        })
      )
    },
  })

  return (
    <div>
      <StyledForm onSubmit={formik.handleSubmit}>
        <StyledTextField
          id="namespaceId"
          select
          size="small"
          name="namespaceId"
          label="Select Namespace"
          margin="normal"
          onChange={formik.handleChange}
          error={
            formik.touched.allowedAction && Boolean(formik.errors.allowedAction)
          }
        >
          {namespaces.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </StyledTextField>
        <StyledTextField
          id="allowedAction"
          select
          size="small"
          name="allowedAction"
          label="Select Action"
          margin="normal"
          onChange={formik.handleChange}
          error={
            formik.touched.allowedAction && Boolean(formik.errors.allowedAction)
          }
        >
          {allowedActionOptions.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </StyledTextField>
        <StyledButtonWrapper>
          <Button color="secondary" variant="contained" type="submit">
            Add Permission
          </Button>
        </StyledButtonWrapper>
      </StyledForm>
    </div>
  )
}

export default PermissionCreateForm
