import styled from 'styled-components'
import { Box, Button, FormControl, FormControlLabel } from '@mui/material'

export const StyledHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 20px 0;
`

export const StyledButton = styled(Button)`
  width: fit-content;
`

export const StyledFormControl = styled(FormControl)`
  && {
    display: block;
    margin: 10px 0;
  }
`

export const StyledFormControlLabel = styled(FormControlLabel)`
  text-transform: capitalize;
`

export const SecurityFormContainer = styled(Box)`
  min-width: 350px;
`

export const StyledButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  margin: 0;
  align-items: center;
`
