import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { ProtectedGateway } from '../outlets/ProtectedGateway'
import { Profile } from '../pages/Profile'
import { Invitations } from '../pages/Invitations'
import { keldaBlackTheme } from '../theme/KeldaPortalBlackTheme'
import {
  LOCAL_ADMIN_ROUTE,
  LOCAL_COMPANY_ROUTE,
  LOCAL_PROFILE_ROUTE,
  LOCAL_INVITATIONS_ROUTE,
  LOCAL_INVITE_ROUTE,
  LOCAL_USERS_ROUTE,
  LOCAL_ACCESS_CONTROL_ROUTE,
  LOCAL_SECURITY_INFO_ROUTE,
} from '../configs/routes'
import { Invite } from '../pages/Invite'
import { GuestGateway } from '../outlets/GuestGateway'
import { AdminLayout } from '../outlets/AdminLayout'
import { AppLayout } from '../outlets/AppLayout'
import { SecurityInfo } from '../pages/SecurityInfo'
import { Organizations } from '../pages/Organizations'
import { Users } from '../pages/Users'
import { AccessControl } from '../pages/AccessControl'
import {
  SHOULD_INCLUDE_ORGANIZATIONS,
  SHOULD_INCLUDE_ACCESS_CONTROL,
} from '../configs/constants'

const APP = (): JSX.Element => {
  return (
    <ThemeProvider theme={keldaBlackTheme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route element={<ProtectedGateway />}>
            <Route element={<AppLayout />}>
              <Route path={LOCAL_PROFILE_ROUTE} element={<Profile />}></Route>
              <Route
                path={LOCAL_SECURITY_INFO_ROUTE}
                element={<SecurityInfo />}
              ></Route>
              <Route path={LOCAL_ADMIN_ROUTE} element={<AdminLayout />}>
                <Route path={LOCAL_USERS_ROUTE} element={<Users />}></Route>
                {SHOULD_INCLUDE_ORGANIZATIONS && (
                  <Route
                    path={LOCAL_COMPANY_ROUTE}
                    element={<Organizations />}
                  ></Route>
                )}
                {SHOULD_INCLUDE_ACCESS_CONTROL && (
                  <Route
                    path={LOCAL_ACCESS_CONTROL_ROUTE}
                    element={<AccessControl />}
                  ></Route>
                )}
                <Route
                  path={LOCAL_INVITATIONS_ROUTE}
                  element={<Invitations />}
                ></Route>
                <Route
                  index
                  element={<Navigate to={LOCAL_INVITATIONS_ROUTE} />}
                />
              </Route>
            </Route>
          </Route>
          <Route element={<GuestGateway />}>
            <Route path={LOCAL_INVITE_ROUTE} element={<Invite />} />
          </Route>
          <Route path="*" element={<Navigate to={LOCAL_PROFILE_ROUTE} />} />
        </Routes>
      </Router>
    </ThemeProvider>
  )
}

export default APP
