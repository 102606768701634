import { Table, TableRow } from '@mui/material'
import { styled } from 'styled-components'

export const StyledTable = styled(Table)`
  min-width: 650px;
`

export const StyledTableRow = styled(TableRow)`
  &:last-child td,
  &:last-child th {
    border: 0;
  }
`
