import { createAction } from '@reduxjs/toolkit'
import { SecurityInfoObj } from './slice'

const SECURITY_INFO_PREFIX = 'SECURITY_INFO'

export interface UpdateSecurityInfoPayload {
  securityInfo: SecurityInfoObj
  postSuccessHandler: () => void
  errorHandler: () => void
}

export const fetchSecurityInfoAction = createAction(
  `${SECURITY_INFO_PREFIX}/API/FETCH_SECURITY_INFO`
)

export const updateSecurityInfoAction = createAction<UpdateSecurityInfoPayload>(
  `${SECURITY_INFO_PREFIX}/API/UPDATE_SECURITY_INFO`
)
